.link {
  @apply p-6 rounded-lg w-56 mx-6 text-sm ;

  color: theme('colors.primary.body.base');
  text-decoration: none;
  box-shadow: 0px 0.25rem 1rem rgba(10, 10, 10, 0.2);
  border-top: 0.5rem solid #D13239;
}

.title {
  @apply font-bold mb-1 text-base;

  color: theme('colors.primary.body.base');
}

@import './proxima-nova.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {
  h1 {
    @apply text-6xl;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-2xl;
  }

  /*a {
    @apply underline;
  }*/

  a {
    @apply no-underline cursor-pointer;
    color: #2175da;
  }

  a:hover {
    color: #363636;
  }
}

@layer components {

  /******************* navigation navbar *********************/
  * {
    font-family: 'proxima-nova';
  }

  .navbar {
    @apply py-0 px-10;
  }

  .has-text-weight-semibold {
    @apply font-semibold;
  }

  .has-text-dark {
    color: #25282a !important;
  }

  .is-size-1pt125 {
    @apply text-lg;
  }

  .has-text-centered {
    @apply text-center;
  }

  .has-mv-m {
    @apply my-6;
  }

  .navbar-link:not(.is-arrowless)::after {
    @apply border-transparent rounded-sm border-r-0 border-t-0 border-solid block absolute top-1/2 w-2.5;
    border-width: 3px;
    content: ' ';
    height: 0.625em;
    margin-top: -0.4375em;
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .title {
    @apply font-semibold text-3xl leading-4;
    color: rgba(37, 40, 42, 1);
  }

  .title,
  .subtitle {
    word-break: break-word;
  }

  .title sub,
  .subtitle sub {
    @apply text-xs;
  }

  .title sup,
  .subtitle sup {
    @apply text-xs;
  }

  .title .tag,
  .subtitle .tag {
    @apply align-middle;
  }
  .title + .highlight {
    @apply mt-3;
  }

  .navbar {
    @apply relative z-30;
    background-color: #ffffff;
    min-height: 3.25rem;
  }
  .navbar > .container {
    @apply flex items-stretch w-full;
    min-height: 3.25rem;
  }

  .navbar.has-shadow {
    box-shadow: 0 2px 0 0 whitesmoke;
  }

  .navbar-menu {
    @apply hidden;
  }

  .navbar-item,
  .navbar-link {
    @apply block leading-normal py-2 px-3 relative text-white;
    color: #25282a;
    font-family: 'proxima-nova';
    font-size: 1.1rem;
    letter-spacing: 0;
    line-height: 0.5;
  }

  .divider {
    height: 10px;
    background-color: #ddd;
    border-radius: 1rem;
  }

  .shadow-new {
    box-shadow: 0px 0px 25px #ccc;
  }

  .home-box-shadow {
    box-shadow: 0px 3px 12px 1px rgba(36, 39, 41, 0.15);
  }

  .short-title {
    font-weight: normal;
    font-size: 1.125rem;
    color: #607282;
    line-height: 1.625rem;
  }

  a.navbar-item,
  .navbar-link {
    @apply cursor-pointer;
  }

  .navbar-item {
    @apply block flex-grow-0 flex-shrink-0;
    font-family: 'proxima-nova';
  }

  .navbar-item img {
    @apply max-h-7;
  }

  .navbar-item.has-dropdown {
    @apply p-0;
  }

  .navbar-item.is-tab {
    @apply border border-solid border-transparent;
    min-height: 3.25rem;
    padding-bottom: calc(0.5rem - 5px);
    border-width: 0 0 5px 0;
  }

  .navbar-item.is-tab:focus,
  .navbar-item.is-tab:hover {
    @apply bg-transparent;
    border-bottom-color: #d13239;
  }

  .navbar-item.is-tab button {
    font-weight: 700;
  }

  .navbar-item.is-tab.is-active {
    @apply bg-transparent border-solid;
    border-bottom-color: #d13239;
    border-bottom-width: 5px;
    color: #2175da;
    padding-bottom: calc(0.5rem - 5px);
  }

  .navbar-content {
    @apply flex-grow flex-shrink;
  }
}

body {
  margin: 0;
  font-family: 'proxima-nova', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h-25 {
  height: 6.5rem;
}

.services-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.sign-up {
  margin-left: 16rem;
}

.opacity-1 {
  opacity: 1 !important;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: white;
  margin-top: -0.375em;
  right: 1.125em;
}

.navbar-dropdown {
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: whitesmoke;
  border: none;
  display: none;
  height: 2px;
  margin: 0.5rem 0;
}

.mob-title {
  color: #25282a;
  font-family: 'proxima-nova';
  font-size: 15px;
}

@media screen and (min-width: 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 789px) {
  .is-hidden-mid {
    display: none !important;
  }
}

@media all and (min-width: 1200px) {
  .heroContainer {
    background-position: center;
  }
}

@media all and (min-width: 992px) and (max-width: 1023px) {
  .heroContainer {
    background-position: center;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .heroContainer {
    background-position: center;
  }
}

@media all and (min-width: 541px) and (max-width: 767px) {
  .heroContainer {
    background-position: center;
  }
}

@media all and (min-width: 481px) and (max-width: 540px) {
  .heroContainer {
    background-position: 82rem 40rem;
  }
}

@media all and (min-width: 321px) and (max-width: 480px) {
  .heroContainer {
    background-position: 82rem 40rem;
  }
}

@media all and (min-width: 281px) and (max-width: 320px) {
  .heroContainer {
    background-position: 82rem 40rem;
  }
}

@media all and (max-width: 280px) {
  .heroContainer {
    background-position: 82rem 40rem;
  }
}

@media screen and (min-width: 768px) {
  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    height: 100%;
    margin: auto 0px;
  }

  .navbar.is-spaced {
    padding: 1rem 2rem;
  }

  .navbar.is-spaced .navbar-start,
  .navbar.is-spaced .navbar-end {
    align-items: center;
  }

  .navbar.is-spaced a.navbar-item,
  .navbar.is-spaced .navbar-link {
    border-radius: 4px;
  }

  .navbar.is-transparent a.navbar-item:focus,
  .navbar.is-transparent a.navbar-item:hover,
  .navbar.is-transparent a.navbar-item.is-active,
  .navbar.is-transparent .navbar-link:focus,
  .navbar.is-transparent .navbar-link:hover,
  .navbar.is-transparent .navbar-link.is-active {
    background-color: transparent !important;
  }

  .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link,
  .navbar.is-transparent
    .navbar-item.has-dropdown.is-hoverable:focus
    .navbar-link,
  .navbar.is-transparent
    .navbar-item.has-dropdown.is-hoverable:focus-within
    .navbar-link,
  .navbar.is-transparent
    .navbar-item.has-dropdown.is-hoverable:hover
    .navbar-link {
    background-color: transparent !important;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item:focus,
  .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
    background-color: whitesmoke;
    color: #0a0a0a;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    background-color: whitesmoke;
    color: #2175da;
  }

  .navbar-desktop {
    display: block;
  }

  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item {
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link::after {
    transform: rotate(135deg) translate(0.25em, -0.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-bottom: 5px solid #d13239;
    border-radius: 6px 6px 0 0;
    border-top: none;
    bottom: 100%;
    box-shadow: 0 -8px 8px rgba(10, 10, 10, 0.1);
    top: auto;
  }

  .navbar-item.is-active .navbar-dropdown,
  .navbar-item.is-hoverable:focus .navbar-dropdown,
  .navbar-item.is-hoverable:focus-within .navbar-dropdown,
  .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown,
  .navbar-item.is-active .navbar-dropdown.is-boxed,
  .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown,
  .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed,
  .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown,
  .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed,
  .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown,
  .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    background-color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 5px solid #d13239;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
    display: none;
    font-size: 0.875rem;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: 20;
  }

  .navbar-dropdown .navbar-item {
    padding: 0.375rem 1rem;
    white-space: nowrap;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown a.navbar-item:focus,
  .navbar-dropdown a.navbar-item:hover {
    background-color: whitesmoke;
    color: #0a0a0a;
  }

  .navbar-dropdown a.navbar-item.is-active {
    background-color: whitesmoke;
    color: #2175da;
  }

  .navbar.is-spaced .navbar-dropdown,
  .navbar-dropdown.is-boxed {
    border-radius: 6px;
    border-top: none;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    display: block;
    opacity: 0;
    top: calc(100% + (5px));
    transform: translateY(-5px);
    transition-duration: 86ms;
    transition-property: opacity, transform;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }
  .navbar > .container .navbar-brand,
  .container > .navbar .navbar-brand {
    margin-left: -0.75rem;
  }
  .navbar > .container .navbar-menu,
  .container > .navbar .navbar-menu {
    margin-right: -0.75rem;
  }

  .navbar.is-fixed-bottom-desktop,
  .navbar.is-fixed-top-desktop {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px rgba(10, 10, 10, 0.1);
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  html.has-navbar-fixed-top-desktop,
  body.has-navbar-fixed-top-desktop {
    padding-top: 3.25rem;
  }

  html.has-navbar-fixed-bottom-desktop,
  body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 3.25rem;
  }

  html.has-spaced-navbar-fixed-top,
  body.has-spaced-navbar-fixed-top {
    padding-top: 5.25rem;
  }

  html.has-spaced-navbar-fixed-bottom,
  body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 5.25rem;
  }

  a.navbar-item.is-active,
  .navbar-link.is-active {
    color: #0a0a0a;
  }

  a.navbar-item.is-active:not(:focus):not(:hover),
  .navbar-link.is-active:not(:focus):not(:hover) {
    background-color: transparent;
  }
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 3.25rem);
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}

.columns:last-child {
  margin-bottom: -0.75rem;
}

.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 0.75rem);
}

.columns.is-centered {
  justify-content: center;
}
@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-desktop {
    display: flex;
  }

  .navbar-hamburger {
    display: none;
  }
}

@media screen and(max-width:768px) {
  .navbar-hamburger {
    color: #d13239;
    background-color: darkblue;
  }

  .navbar-hamburger.is-active {
    display: block;
  }
}

.navbar-brand {
  height: 4.5rem;
}

@media screen and (max-width: 1023px) {
  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1023px) {
  .navbar-end {
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 1023px) {
  .navbar-menu {
    padding-bottom: 5rem;
  }
}

.navbar-left-container {
  display: flex;
}

.hide-mobile {
  display: flex;
}

@media screen and (max-width: 1023px) {
  .hide-mobile {
    display: none;
  }
}

.navbar-right-container {
  display: none;
}

@media screen and (max-width: 1023px) {
  .navbar-right-container {
    display: flex;
  }
}

.navbar-brand a {
  padding: 0.5rem 0;
}

/* Mega-Nav Styles
 ************************************ */
.navbar-item.is-tab:hover {
  border-bottom-width: 5px;
  padding-bottom: calc(0.5rem - 5px);
}

@media screen and (max-width: 1023px) {
  .navbar-item.is-tab:hover {
    border-bottom-color: #25282a;
  }
}

@media screen and (min-width: 769px), print {
  .navbar-link {
    margin-top: 0.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .mega-nav-container {
    width: 1024px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.mega-nav {
  padding: 0 1.5625rem;
  color: black;
  height: 28.75rem;
}

@media screen and (max-width: 1023px) {
  .mega-nav {
    display: none;
  }
}

.rectangle {
  margin-top: 0.6875rem;
  box-sizing: border-box;
  height: 26.75rem;
  padding: 1.25rem 0.3125rem 0 0.3125rem;
}

.rectangle .title {
  font-size: 1.125rem;
}

.rectangle.coaching {
  height: auto;
  border: 1px solid transparent;
}

.rectangle.coaching:hover {
  border: 1px solid #d8e1e8;
  border-radius: 1rem;
  background: #f0f4f5;
  padding: 1.25rem 0.3125rem 0 0.3125rem;
}

.coaching-vids {
  margin-top: 0.6875rem;
  padding: 1.25rem 0.3125rem 0 0.3125rem;
}

.has-width-460 {
  width: 460px;
}

.rectangle:hover {
  border: 1px solid #d8e1e8;
  border-radius: 1rem;
  background: #f0f4f5;
  padding: 1.1875rem 0.25rem 0 0.25rem;
}

.title-box {
  border-bottom: 1px solid #cad1d7;
  padding-bottom: 1.25rem;
  margin-bottom: 1.5625rem;
}

.sup {
  font-size: 0.5rem;
}

@media screen and (min-width: 1024px) {
  .navbar-dropdown {
    position: fixed;
    min-width: 1024px;
    left: calc((100vw - 1024px) / 2);
  }
}

@media screen and (max-width: 1023px) {
  .navbar-dropdown {
    margin-left: 0;
  }
}

.white {
  color: white;
}

@media screen and (min-width: 1024px) {
  .mobile-nav {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .mobile-nav .mobile-title {
    text-decoration: underline;
    margin-top: -0.8rem;
  }

  .mobile-nav .mobile-subtitle {
    margin-top: -20px;
  }

  .hideNav {
    display: none;
  }
}

.navbar-link:not(.is-arrowless)::after {
  right: 1.7em;
  border: 1px solid #25282a;
  border-right: 0;
  border-top: 0;
  border-radius: 1px;
  top: 54%;
  height: 7.5px;
  width: 7.5px;
}

.navbar-link-active:not(.is-arrowless)::after {
  border: 1px solid #25282a;
  border-left: 0;
  border-bottom: 0;
  border-radius: 1px;
  top: 58%;
}

@media screen and (max-width: 769px), print {
  .navbar-item.is-tab.is-active {
    border-bottom-width: 0;
  }
}

.coaching-container {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
}

.coaching__title__container {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}

.coaching__title {
  font-weight: 400;
  font-size: 42px;
  line-height: 28px;
  font-family: 'DM Serif Display Regular';
}

.coaching__subtitle {
  display: inline;
  z-index: 100;
  color: #4a4a4a;
  text-decoration: none;
}

.navbar-desktop {
  display: none;
}

.has-text-link {
  color: #2175da !important;
}

.grid-column-2-layout {
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 769px) {
  .marketing-details {
    display: grid;
    grid-template-areas:
      'first'
      'second'
      'third'
      'fourth'
      'fifth'
      'sixth';
  }

  .item1 {
    grid-area: first;
    grid-column: span 2 / span 2;
  }

  .item2 {
    grid-area: second;
    grid-column: span 2 / span 2;
  }

  .item3 {
    grid-area: third;
    grid-column: span 2 / span 2;
  }

  .item4 {
    grid-area: fourth;
    grid-column: span 2 / span 2;
  }

  .item5 {
    grid-area: fifth;
    grid-column: span 2 / span 2;
  }
  .item6 {
    grid-area: sixth;
    grid-column: span 2 / span 2;
  }
}

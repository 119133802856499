.footerLogo {
  height: auto;
  width: 100%;
}

.footerLinks p {
  @apply mb-2.5;
}
.footerLinks a {
  @apply no-underline;
}

.footerLinks a,
.footerLinks a:focus,
.footerLinks a:hover,
.footerLinks a:active,
.extraInfo a,
.extraInfo a:focus,
.extraInfo a:hover,
.extraInfo a:active {
  color: #25282a;
}

.footerLoggedIn {
  background-color: #25282a;
  color: white;
}

.footerLoggedIn .footerLinks,
.footerLoggedIn .extraInfo,
.footerLoggedIn .extraInfo div {
  text-align: left;
}
.extraInfo {
  line-height: 1.5;
}

.footerLoggedIn .content {
  border-width: 0;
}

.footerLoggedIn a,
.footerLoggedIn a:hover,
.footerLoggedIn a:focus,
.footerLoggedIn a:visited {
  color: white;
}

@media all and (min-width: 768px) {
  .logo {
    flex-grow: 0;
    flex-shrink: 1;
  }
}

.checkbox > input {
  height: 24px;
  width: 24px;
  border-radius: 4px;
}
.checkbox > input:checked + span::before {
  content: ' ';
  background-image: url(Assets/icons/check-tick.svg);
  background-size: 94%;
  background-color: transparent;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  font-size: small;
  position: absolute;
  left: 0.05rem;
  top: 0.05rem;
  z-index: 50;
  font-family: proxima-nova, Helvetica, Arial;
}

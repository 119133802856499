.overlay {
  position: fixed;
  top: 3.25rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 30;
  box-shadow: 0 8px 16px rgb(10 10 10 / 10%);
}
.inner {
  text-align: left;
  z-index: 35;
  overflow: scroll;
}
.top325 {
  top: 3.25rem;
}

.topMinus100vh {
  top: -100vh;
}

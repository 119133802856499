@font-face {
  font-family: 'proxima-nova';
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima_nova_regular.woff2?version=.Ip7JDqhugOYTA76RSljx3J.zY_EAETC')
      format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: local('Proxima Nova SemiBold'), local('ProximaNova-SemiBold'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima_nova_semibold.woff2?version=dHAD64P7ayBbEUSiBD.KATFw02LP.ZSS')
      format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima_nova_bold.woff2?version=gjFvEHOoOfvUdsz6PjVpqx6Baw_j5.Uv')
      format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: local('Proxima Nova Black'), local('ProximaNova-Black'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima-nova-black.woff2')
      format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: local('Proxima Nova Condensed'), local('ProximaNova-Condensed'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima_nova_condensed_regular.woff2')
      format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
}

.navbarBurger {
  @apply text-primary-body-base;
  cursor: pointer;
  display: block;
  height: 3.25rem;
  position: relative;
  width: 3.25rem;
  margin-left: auto;
  transition-duration: 450ms;
  transition-property: background-color;
  transition-timing-function: ease-out;
}
.navbarBurger span {
  @apply bg-primary-body-base;
  display: block;
  height: 2px;
  left: calc(50% - 8px);
  position: absolute;
  transform-origin: center;
  transition-duration: 86ms;
  transition-property: background-color, opacity, transform, height;
  transition-timing-function: ease-out;
  width: 16px;
}
.navbarBurger:hover span {
  @apply bg-primary-body-dark;
}
.navbarBurger span:nth-child(1) {
  top: calc(50% - 6px);
}
.navbarBurger span:nth-child(2) {
  top: calc(50% - 1px);
}
.navbarBurger span:nth-child(3) {
  top: calc(50% + 4px);
}
.navbarBurger.isActive span:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}
.navbarBurger.isActive span:nth-child(2) {
  opacity: 0;
}
.navbarBurger.isActive span:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}
